// core
import React, { useState, useEffect, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
// redux actions
import { uiActions } from '../../store/domains/ui/actions';
// components
import { selectComponents } from './componets/customSelectComponents';
// instruments
import {
  defineOsBigIconForVnc,
  defineBrowserBigIcon,
  defineOsTitleForVnc,
  computeMainBlockHeightWithAlerts,
  defineBrowserTitle,
  runLiveTest,
} from '../../helpers';
import { selectStyles } from './stylesAndSettings';
import { useAuth0 } from '../../helpers/auth';
import { api } from '../../api';
//socket
import { _socket as socket } from '../../socket';
// images
import Icons from '../../images';
import osBigIcons from '../../images/osBigIcons';
// styles
import './pageRealTimeTesting.scss';
// typescript types
import { RootState } from '../../store/rootReducer';
type OptionType = {
  value: string;
  label: string;
  icon?: string;
};

const PageRealTimeTesting: FC = () => {
  // hooks
  const dispatch = useDispatch();
  const isThanksForPurchaseAlertActive = useSelector(
    (state: RootState) => state.ui.isThanksForPurchaseAlertActive
  );
  const isPlanActiveAlertActive = useSelector(
    (state: RootState) => state.ui.isPlanActiveAlertActive
  );
  const userLimitsAndStats = useSelector((state: RootState) => state.userLimitsAndStats);
  const isPaymentDeatilsUpdatedAlertActive = useSelector(
    (state: RootState) => state.ui.isPaymentDeatilsUpdatedAlertActive
  );
  const configsLivetesting = useSelector((state: RootState) => state.configsLivetesting);
  const isMobile = useMediaQuery({ maxWidth: 768 }); // if screen width is less than 768px
  const isXSMobile = useMediaQuery({ maxWidth: 576 }); // check if screen width is less than or equal to 576px
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [os, setOs] = useState<string>();
  const [browserSelectValue, setBrowserSelectValue] = useState<OptionType>();
  const [browserSelectOptions, setBrowserSelectOptions] = useState<OptionType[]>();
  const [versionSelectValue, setVersionSelectValue] = useState<OptionType>();
  const [versionSelectOptions, setVersionSelectOptions] = useState<OptionType[]>();
  const [isRunLiveTestBtnDisabled, setIsRunLiveTestBtnDisabled] = useState(false);
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const { user, isAppSumoAlertActive } = useAuth0();

  // hide popup on click on overlay
  useEffect(() => {
    function hidePopup(event: MouseEvent): void {
      if (isPopupActive && popupRef.current === event.target) {
        setIsPopupActive(false);
      }
    }

    document.addEventListener('click', hidePopup);

    return () => document.removeEventListener('click', hidePopup);
  }, [isPopupActive]);

  // check if the user has reached the limit of screenshots per month
  const { isLiveSessionsLimitReached, areLiveSessionsUnlimited } = userLimitsAndStats;

  // compute CSS height of 'RealTimeTestingPage' block
  const style = computeMainBlockHeightWithAlerts({
    isXSMobile,
    isThanksForPurchaseAlertActive,
    isPlanActiveAlertActive,
    isPaymentDeatilsUpdatedAlertActive,
    isAppSumoAlertActive,
  });

  function handleFocus() {
    inputRef.current.select();
  }

  function handleCloseClick() {
    setIsPopupActive(false);
  }

  function handleOsClick(targetOs: string): void {
    let currentVersions: OptionType[];
    const browserSelectOptions = Object.keys(configsLivetesting[targetOs]).map((item) => ({
      value: item,
      label: defineBrowserTitle(item),
      icon: defineBrowserBigIcon(item),
    }));

    setBrowserSelectOptions(browserSelectOptions);

    function getCurrentBrowserVersions(browser: string): OptionType[] {
      return configsLivetesting[targetOs][browser]
        .map((version: string) => ({ value: version, label: version }))
        .sort((x, y) => +y.value - +x.value);
    }

    if (os === targetOs) {
      setBrowserSelectValue(browserSelectValue);
      currentVersions = getCurrentBrowserVersions(browserSelectValue.value);
      setVersionSelectOptions(versionSelectOptions);
      setVersionSelectValue(versionSelectValue);
    } else {
      setBrowserSelectValue(browserSelectOptions[0]);
      currentVersions = getCurrentBrowserVersions(browserSelectOptions[0].value);
      setVersionSelectOptions(currentVersions);
      setVersionSelectValue(currentVersions[0]);
    }

    setOs(targetOs);
    setIsPopupActive(true);
  }

  function handleBrowserSelectChange(value: OptionType) {
    const browser = value.value;

    setBrowserSelectValue(value);

    const currentVersions = configsLivetesting[os][browser]
      .map((version: string) => ({ value: version, label: version }))
      .sort((x, y) => +y.value - +x.value);

    setVersionSelectOptions(currentVersions);
    setVersionSelectValue(currentVersions[0]);
  }

  function handleVersionSelectChange(value: OptionType) {
    setVersionSelectValue(value);
  }

  function handleRunLiveTestClick() {
    setIsRunLiveTestBtnDisabled(true);

    const params = {
      socket,
      dispatch,
      uiActions,
      isLiveSessionsLimitReached,
      areLiveSessionsUnlimited,
      user,
      api,
      os,
      browser: browserSelectValue.value,
      version: versionSelectValue.value,
      url: inputRef.current.value,
      setIsPopupActive,
      setIsRunLiveTestBtnDisabled,
    };

    runLiveTest(params);
  }

  if (isMobile) {
    navigate('/');
    return null;
  }

  return (
    <main className="realTimeTestingPage" style={style}>
      <div className="container_medium">
        <div className="realTimeTestingPage__content">
          <input
            type="url"
            name="url"
            autoComplete="off"
            ref={inputRef}
            className="realTimeTestingPage__input"
            defaultValue="https://www.google.com"
            onFocus={handleFocus}
          />
          <div className="realTimeTestingPage__buttons">
            <button className="realTimeTestingPage__button" onClick={() => handleOsClick('LINUX')}>
              <span className="realTimeTestingPage__button-background linux" />
              <img
                src={osBigIcons.linux}
                alt={defineOsTitleForVnc('LINUX')}
                className="realTimeTestingPage__button-img"
              />
              <span className="realTimeTestingPage__button-label">
                {defineOsTitleForVnc('LINUX')}
              </span>
            </button>
            <button
              className="realTimeTestingPage__button"
              onClick={() => handleOsClick('10WINDOWS')}
            >
              <span className="realTimeTestingPage__button-background windows" />
              <img
                src={osBigIcons.windows10White}
                alt={defineOsTitleForVnc('10WINDOWS')}
                className="realTimeTestingPage__button-img"
              />
              <span className="realTimeTestingPage__button-label">
                {defineOsTitleForVnc('10WINDOWS')}
              </span>
            </button>
            <button className="realTimeTestingPage__button" disabled>
              <span className="realTimeTestingPage__button-background mac" />
              <img
                src={osBigIcons.macDisabled}
                alt={defineOsTitleForVnc('OSX')}
                className="realTimeTestingPage__button-img"
              />
              <span className="realTimeTestingPage__button-label">
                {defineOsTitleForVnc('OSX')}
              </span>
              <span className="realTimeTestingPage__button-coming-soon">Coming soon</span>
            </button>
          </div>
        </div>
      </div>
      {isPopupActive && (
        <div className="realTimeTestingPage__popup popup-overlay" ref={popupRef}>
          <div className="realTimeTestingPage__popup-content popup">
            <div className="realTimeTestingPage__popup-heading-wrapper">
              <img
                src={defineOsBigIconForVnc(os)}
                alt=""
                className="realTimeTestingPage__popup-img"
              />
              <h2 className="realTimeTestingPage__popup-heading">
                {defineOsTitleForVnc(os)} real-time session
              </h2>
            </div>
            <div className="realTimeTestingPage__popup-selects">
              <div className="realTimeTestingPage__popup-selects-item">
                <p className="realTimeTestingPage__popup-selects-item-label">Browser</p>
                <Select
                  className="realTimeTestingPage__popup-selects-item-select"
                  classNamePrefix="realTimeTestingPage__popup-selects-item-select"
                  value={browserSelectValue}
                  options={browserSelectOptions}
                  isSearchable={false}
                  styles={selectStyles}
                  onChange={handleBrowserSelectChange}
                  components={selectComponents}
                />
              </div>
              <div className="realTimeTestingPage__popup-selects-item">
                <p className="realTimeTestingPage__popup-selects-item-label">Version</p>
                <Select
                  className="realTimeTestingPage__popup-selects-item-select"
                  classNamePrefix="realTimeTestingPage__popup-selects-item-select"
                  value={versionSelectValue}
                  options={versionSelectOptions}
                  isSearchable={false}
                  styles={selectStyles}
                  onChange={handleVersionSelectChange}
                  components={selectComponents}
                />
              </div>
            </div>
            <button
              className="realTimeTestingPage__popup-run-btn orange-gradient-btn one-border-active"
              onClick={handleRunLiveTestClick}
              disabled={isRunLiveTestBtnDisabled}
            >
              RUN LIVE TEST
            </button>
            <button
              className="realTimeTestingPage__popup-close-btn square-btn"
              onClick={handleCloseClick}
            >
              <Icons.cross />
            </button>
          </div>
        </div>
      )}
    </main>
  );
};

export default PageRealTimeTesting;
