export const selectStyles = {
  container: (styles) => ({
    ...styles,
    width: 195,
    cursor: 'pointer',
  }),
  menu: (styles) => ({
    ...styles,
    margin: '6px 0 0',
    background: '#fff',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: 'none',
    padding: '2px 2px 0 2px',
  }),
  option: (styles, state) => ({
    marginBottom: 2,
    padding: '7px 9px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: state.isSelected ? '#3DBFE4 !important' : '#fff',
    color: state.isSelected ? '#fff !important' : '#656A73',
    cursor: 'pointer',
    height: 40,
    '&:hover': {
      backgroundColor: '#F1F3F8',
    },
  }),
  control: () => ({
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: '#fff',
    borderRadius: 5,
    border: '1px solid #C0C3C9',
    '&:hover': {
      border: '1px solid #606772',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    position: 'absolute',
    top: '50%',
    left: 10,
    transform: 'translateY(-50%)',
    color: '#606772',
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: 40,
  }),
};
