// core
import React from 'react';
// styles
import { components } from 'react-select';

const { Option } = components;

function CustomOption(props) {
  return (
    <Option {...props}>
      {props.data.icon && (
        <span className="realTimeTestingPage__popup-selects-item-select__option-icon-wrapper">
          <img
            src={props.data.icon}
            alt=""
            className="realTimeTestingPage__popup-selects-item-select__option-icon"
          />
        </span>
      )}
      {props.data.label}
    </Option>
  );
}

// custom React Select components to add icon to Option and SingleValue
export const selectComponents = {
  Option: CustomOption,
  IndicatorsContainer: () => null,
};
